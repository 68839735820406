import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpProductEntity from "../../entity";
import UtilsErp from "../../../../../utils/erp";

export default class ErpProductErp {
    private parent: any;
    private entity = "erp/product";
    private toastr: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...ErpProductEntity.getEntityData(elem), id: this.parent.id}
    }

    fixSerials(serials: any) {
        if (serials.indexOf(",") !== -1) {
            serials = serials.split(",")
        }else if (serials.indexOf(";") !== -1) {
            serials = serials.split(";")
        } else if (serials.indexOf(" ") !== -1) {
            serials = serials.split(" ")
        } else if (serials.indexOf("\n") !== -1) {
            serials = serials.split("\n")
        }
        serials = typeof serials === 'object' ? serials : [serials]
        return serials.filter((n: any) => n).map((s: any) => s.replace(/,/g, "").replace(/ /g, "").replace(/;/g, ""))
    }
    checkDimensions(warehouseLocationId: string, amount: number) {
        return true;
    }

    bindListeners() {
        jQuery("#erp_product_erp_input_warehouse_location_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_product_erp_input_warehouse_location_pallet_id") as HTMLSelectElement).disabled = false
        });
        jQuery("#erp_product_erp_output_warehouse_location_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_product_erp_output_warehouse_location_pallet_id") as HTMLSelectElement).disabled = false
        });

        jQuery("#erp_product_erp_move_warehouse_location_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_product_erp_move_warehouse_location_pallet_id") as HTMLSelectElement).disabled = false
        });
        jQuery("#erp_product_erp_move_new_warehouse_location_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_product_erp_move_new_warehouse_location_pallet_id") as HTMLSelectElement).disabled = false
        });
        (document.querySelector("#erp_product_erp_output_serials") as HTMLTextAreaElement).addEventListener("change", (e) => {
            const target = e.target as HTMLTextAreaElement
            target.value = this.fixSerials(target.value).join(";")
        });
        (document.querySelector("#erp_product_erp_move_serials") as HTMLTextAreaElement).addEventListener("change", (e) => {
            const target = e.target as HTMLTextAreaElement
            target.value = this.fixSerials(target.value).join(";")
        });
        (document.querySelector("#erp_product_erp_input_serials") as HTMLTextAreaElement).addEventListener("change", (e) => {
            const target = e.target as HTMLTextAreaElement
            target.value = this.fixSerials(target.value).join(";")
        });

        (document.querySelector("#erpProductErpInputForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault()
            const form = (document.querySelector("#erpProductErpInputForm") as HTMLFormElement);

            const warehouseId = (form.querySelector("#erp_product_erp_input_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationId = (form.querySelector("#erp_product_erp_input_warehouse_location_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationPalletId = (form.querySelector("#erp_product_erp_input_warehouse_location_pallet_id option:checked") as HTMLSelectElement)?.value;
            const customerId = (form.querySelector("#erp_product_erp_input_customer_id option:checked") as HTMLSelectElement)?.value;
            const amount = parseInt((form.querySelector("#erp_product_erp_input_amount") as HTMLInputElement).value);
            const bestBefore = (form.querySelector("#erp_product_erp_input_bestBefore") as HTMLInputElement).value;
            const comment = (form.querySelector("#erp_product_erp_input_comment") as HTMLInputElement).value;
            const storedAt = (form.querySelector("#erp_product_erp_input_storedAt") as HTMLInputElement).value;
            const supplierId = (form.querySelector("#erp_product_erp_input_supplier_id option:checked") as HTMLSelectElement)?.value;
            const serials = this.fixSerials((form.querySelector("#erp_product_erp_input_serials") as HTMLInputElement).value);


            if (!warehouseId) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!warehouseLocationId) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_location_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!amount || amount <= 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (serials.length !== amount && serials.length > 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_matching_serials')}`, `${Utils.translate('generic.error')}`)
                return
            }
            const dimCheckResult = await this.checkDimensions(warehouseLocationId, amount);

            let data = {
                id: null,
                productId: this.parent.id,
                warehouseId: warehouseId,
                warehouseLocationId: warehouseLocationId,
                warehouseLocationPalletId: warehouseLocationPalletId,
                customerId: customerId,
                serials: serials,
                stock: amount,
                storedAt: storedAt
            }
            await Utils.showLoader();
            await Utils.entity.upsert(data, "erp/warehouse_location_product_mappings")
            form.reset()
            jQuery('#erp_product_erp_input_warehouse_id').val(null).trigger('change');
            jQuery('#erp_product_erp_input_warehouse_location_id').val(null).trigger('change');
            jQuery('#erp_product_erp_input_supplier_id').val(null).trigger('change');
            jQuery('#erp_product_erp_input_warehouse_location_pallet_id').val(null).trigger('change');
            (document.querySelector("#erp_product_erp_input_warehouse_location_pallet_id") as HTMLSelectElement).disabled = true

            this.toastr.success(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.input_success')}`, `${Utils.translate('generic.success')}`)
            await this.parent.getEntity()
        });

        (document.querySelector("#erpProductErpOutputForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault()
            const form = (document.querySelector("#erpProductErpOutputForm") as HTMLFormElement);

            const warehouseId = (form.querySelector("#erp_product_erp_output_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationId = (form.querySelector("#erp_product_erp_output_warehouse_location_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationPalletId = (form.querySelector("#erp_product_erp_output_warehouse_location_pallet_id option:checked") as HTMLSelectElement)?.value;

            const customerId = (form.querySelector("#erp_product_erp_output_customer_id option:checked") as HTMLSelectElement)?.value;
            const amount = parseInt((form.querySelector("#erp_product_erp_output_amount") as HTMLInputElement).value);
            const bestBefore = (form.querySelector("#erp_product_erp_output_bestBefore") as HTMLInputElement).value;
            const comment = (form.querySelector("#erp_product_erp_output_comment") as HTMLInputElement).value;
            const supplierId = (form.querySelector("#erp_product_erp_output_supplier_id option:checked") as HTMLSelectElement)?.value;
            const serials = this.fixSerials((form.querySelector("#erp_product_erp_output_serials") as HTMLInputElement).value);


            if (!warehouseId) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!warehouseLocationId) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_location_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!amount || amount <= 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (serials.length !== amount && serials.length > 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_matching_serials')}`, `${Utils.translate('generic.error')}`)
                return
            }
            await Utils.showLoader();
            const data = await UtilsErp.outputProducts({
                productId: this.parent.id,
                palletId: warehouseLocationPalletId,
                customerId: customerId,
                warehouseLocationId: warehouseLocationId,
                warehouseId: warehouseId,
                serials: serials,
                quantity: amount
            })
            const messages = [];
            if (data && data.status === 200) {
                if (data.quantity !== 0) {
                    if (data.quantity !== amount) {
                        messages.push(`${amount - data.quantity} successfully outsourced.`)
                    }
                    messages.push(`${data.quantity} products could not get outsourced.`)
                }
                if (data.serials.length !== 0) {
                    messages.push(`${data.serials.join(", ")} serials not found in warehouse.`)
                }
            }

            if (messages.length > 0) {
                this.toastr.warning(`${messages.join("<br />")}`, `${Utils.translate('generic.warning')}`)

            } else {
                this.toastr.success(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.output_success')}`, `${Utils.translate('generic.success')}`)

                form.reset()
                jQuery('#erp_product_erp_output_warehouse_id').val(null).trigger('change');
                jQuery('#erp_product_erp_output_warehouse_location_id').val(null).trigger('change');
                jQuery('#erp_product_erp_output_supplier_id').val(null).trigger('change');
                jQuery('#erp_product_erp_output_warehouse_location_pallet_id').val(null).trigger('change');
                (document.querySelector("#erp_product_erp_output_warehouse_location_pallet_id") as HTMLSelectElement).disabled = true
            }
            await this.parent.getEntity()
        });

        (document.querySelector("#erpProductErpMoveForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault()
            const form = (document.querySelector("#erpProductErpMoveForm") as HTMLFormElement);

            const warehouseId = (form.querySelector("#erp_product_erp_move_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationId = (form.querySelector("#erp_product_erp_move_warehouse_location_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationPalletId = (form.querySelector("#erp_product_erp_move_warehouse_location_pallet_id option:checked") as HTMLSelectElement)?.value;

            const warehouseIdNew = (form.querySelector("#erp_product_erp_move_new_warehouse_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationIdNew = (form.querySelector("#erp_product_erp_move_new_warehouse_location_id option:checked") as HTMLSelectElement)?.value;
            const warehouseLocationPalletIdNew = (form.querySelector("#erp_product_erp_move_new_warehouse_location_pallet_id option:checked") as HTMLSelectElement)?.value;

            const amount = parseInt((form.querySelector("#erp_product_erp_move_amount") as HTMLInputElement).value);
            const customerId = (form.querySelector("#erp_product_erp_move_customer_id option:checked") as HTMLSelectElement)?.value;
            const newCustomerId = (form.querySelector("#erp_product_erp_move_new_customer_id option:checked") as HTMLSelectElement)?.value;

            const serials = this.fixSerials((form.querySelector("#erp_product_erp_move_serials") as HTMLInputElement).value);



            if (!warehouseId || !warehouseIdNew) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!warehouseLocationId || !warehouseLocationIdNew) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.warehouse_location_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (!amount || amount <= 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_set')}`, `${Utils.translate('generic.error')}`)
                return
            }
            if (serials.length !== amount && serials.length > 0) {
                this.toastr.error(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.amount_not_matching_serials')}`, `${Utils.translate('generic.error')}`)
                return
            }

            await Utils.showLoader();
            const data = await UtilsErp.moveProducts({
                productId: this.parent.id,
                palletId: warehouseLocationPalletId,
                newPalletId: warehouseLocationPalletIdNew,
                customerId: customerId,
                newCustomerId: newCustomerId,
                warehouseLocationId: warehouseLocationId,
                newWarehouseLocationId: warehouseLocationIdNew,
                warehouseId: warehouseId,
                newWarehouseId: warehouseIdNew,
                serials: serials,
                quantity: amount
            })
            const messages = [];
            if (data && data.status === 200) {
                if (data.quantity !== 0) {
                    if (data.quantity !== amount) {
                        messages.push(`${amount - data.quantity} successfully moved.`)
                    }
                    messages.push(`${data.quantity} products could not get moved.`)
                }
                if (data.serials.length !== 0) {
                    messages.push(`${data.serials.join(", ")} serials not found in warehouse.`)
                }
            }

            if (messages.length > 0) {
                this.toastr.warning(`${messages.join("<br />")}`, `${Utils.translate('generic.warning')}`)
            } else {
                form.reset();
                await Utils.hideLoader();
                jQuery('#erp_product_erp_move_warehouse_id').val(null).trigger('change');
                jQuery('#erp_product_erp_move_warehouse_location_id').val(null).trigger('change');
                jQuery('#erp_product_erp_move_new_warehouse_id').val(null).trigger('change');
                jQuery('#erp_product_erp_move_new_warehouse_location_id').val(null).trigger('change');
                jQuery('#erp_product_erp_move_warehouse_location_pallet_id').val(null).trigger('change');
                jQuery('#erp_product_erp_move_new_warehouse_location_pallet_id').val(null).trigger('change');
                (document.querySelector("#erp_product_erp_move_warehouse_location_pallet_id") as HTMLSelectElement).disabled = true;
                (document.querySelector("#erp_product_erp_move_new_warehouse_location_pallet_id") as HTMLSelectElement).disabled = true;

                this.toastr.success(`${Utils.translate('warehouse_location.name')} ${Utils.translate('warehouse_location.messages.move_success')}`, `${Utils.translate('generic.success')}`)
            }
            await this.parent.getEntity()
        });

    }

    onCreateNewElement(data: any, entity: any) {
        if (entity === "erp/warehouse_location_pallets") {
            let val = (document.querySelector("#erp_product_erp_input_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            if (!val) {
                val = (document.querySelector("#erp_product_erp_move_new_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
            return {
                warehouse_location_id: val
            }
        } else{
            return {}
        }
    }
    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("id") === "erp_product_erp_input_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#erp_product_erp_input_warehouse_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_input_supplier_id") {
            return {
                product_id: this.parent.id
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_input_warehouse_location_pallet_id") {
            return {
                warehouse_location_id: (document.querySelector("#erp_product_erp_input_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_output_warehouse_location_pallet_id") {
            return {
                warehouse_location_id: (document.querySelector("#erp_product_erp_output_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_move_warehouse_location_pallet_id") {
            return {
                warehouse_location_id: (document.querySelector("#erp_product_erp_move_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_move_new_warehouse_location_pallet_id") {
            return {
                warehouse_location_id: (document.querySelector("#erp_product_erp_move_new_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_output_warehouse_id") {
            return {
                product_id: this.parent.id,
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_output_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#erp_product_erp_output_warehouse_id option:checked") as HTMLSelectElement)?.value,
                product_id: this.parent.id,
            }

        } else if (elem.getAttribute("id") === "erp_product_erp_output_customer_id") {
            const customerId = (document.querySelector("#erp_product_erp_output_customer_id option:checked") as HTMLSelectElement)?.value
            const warehouseLocationId = (document.querySelector("#erp_product_erp_output_warehouse_location_id option:checked") as HTMLSelectElement)?.value
            return {
                customer_id: customerId,
            }

        } else if (elem.getAttribute("id") === "erp_product_erp_move_warehouse_id") {
            return {
                product_id: this.parent.id,
            }
        } else if (elem.getAttribute("id") === "erp_product_erp_move_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#erp_product_erp_move_warehouse_id option:checked") as HTMLSelectElement)?.value,
                product_id: this.parent.id
            }

        } else if (elem.getAttribute("id") === "erp_product_erp_move_new_warehouse_location_id") {
            return {
                warehouse_id: (document.querySelector("#erp_product_erp_move_new_warehouse_id option:checked") as HTMLSelectElement)?.value,
            }
        }
        return {}
    }

    async update(data: any) {
        await Utils.updateElements(data, '', (document.querySelector("#erp_product_erp") as HTMLElement))
    }
}